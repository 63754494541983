body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.references__buttons {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media only screen and (max-width: 700px) {
  .references__buttons {
    justify-content: center;
  }
}

.table-header-container {
  display: flex;
  font-size: 1.6rem;
  color: var(--color-green-secondary);
  padding-left: 5rem;
  padding-right: 5rem;

  margin-bottom: 1rem;
  box-sizing: border-box;
}

@media only screen and (max-width: 1300px) {
  .table-header-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 1100px) {
  .table-header-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 400px) {
  .table-header-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.table-top {
  display: flex;
  width: 50%;
}

.table-header-1 {
  width: 20%;
}

.table-header-2 {
  width: 25%;
}

.table-header-3 {
  width: 55%;
}

.table-header-4 {
  width: 50%;
}

@media only screen and (max-width: 1200px) {
  .table-top {
    width: 60%;
  }
  .table-header-4 {
    width: 40%;
  }
}

@media only screen and (max-width: 1100px) {
  .table-top {
    width: 100%;
  }
  .table-header-4 {
    width: 100%;
    padding-top: 1rem;
    border-top: 1px solid var(--color-porcelain);
  }
  .table-header-4-hidden {
    visibility: hidden;
    height: 0rem;
    border-top: 0px;
  }
  .table-header-1 {
    width: 20%;
  }
  .table-header-2 {
    width: 20%;
  }
  .table-header-3 {
    width: auto;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
}

@keyframes toggleIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.table-item-container {
  animation: toggleIn 1s 1;
  box-sizing: border-box;
  display: flex;
  min-height: 8rem;
  font-size: 1.6rem;
  color: var(--color-darkblue-primary);
  background-color: var(--color-white-tint);
  border-left: 3px solid var(--color-green-secondary);
  padding-top: 1rem;
  padding-bottom: 1rem;
  -webkit-box-shadow: 2px 2px 15px -11px rgba(176, 176, 176, 0.66);
  -moz-box-shadow: 2px 2px 15px -11px rgba(176, 176, 176, 0.66);
  box-shadow: 2px 2px 15px -11px rgba(176, 176, 176, 0.66);
}

@media only screen and (max-width: 400px) {
  .table-item-container {
    font-size: 1.4rem;
  }
}

.table-item-container.active {
  box-sizing: border-box;
  border-left: 3px solid var(--color-references-ongoing);
}

.table-item-container.info {
  box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.5s;
  border-top: 1px solid var(--color-white-tint);
  border-bottom: 1px solid var(--color-white-tint);
}

.table-item-container.info:hover {
  box-sizing: border-box;
  background-color: #f7f7f7;
  border-top: 1px solid var(--color-porcelain);
  border-bottom: 1px solid var(--color-porcelain);
}

.table-item-1 {
  display: flex;
  align-items: center;
}

.table-item-2 {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: var(--color-green-secondary);
}

.table-item-2.active {
  color: var(--color-references-ongoing);
}

.table-item-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.table-item-3 span {
  margin-top: 0.4rem;
}

@media only screen and (max-width: 1100px) {
  .table-item-3 {
    align-items: flex-end;
    padding-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 700px) {
  .table-item-1,
  .table-item-2 {
    font-size: 1.3rem;
  }
  .table-item-3 {
    font-size: 1.4rem;
  }
}

.table-item-4 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table-item-4 span {
  margin-top: 0.4rem;
}

.table-item-4--main {
  display: flex;
  flex-direction: column;
}

.table-item-4--info {
  margin-left: 2rem;
  color: var(--color-green-secondary);
}

.table-bottom-text {
  color: grey;
  font-size: 1.4rem;
}
@media only screen and (max-width: 700px) {
  .table-bottom-text {
    font-size: 1.2rem;
  }
}

.table-pages {
  display: flex;
  justify-content: center;
}
